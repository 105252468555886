export type SerialSettingType = {
     name? : string,
     filter? : any ,
     baudRate?:number 

 };
 
 
export class Serial  {

    private _port?:any ;
    private _settings?:any = { acceptAllDevices:true  } ;
    private _info?:any ;
    private _reader?:any ;
    
    constructor(
    settings : SerialSettingType 
     ) {
        this._settings = settings; 
        this._reader=false;
    }
    
    
    getInfoCacheIfExsist(){
        if(this._settings.name ){
            try{
              
                   const s :any = localStorage.getItem( this._settings.name );
                  return JSON.parse(s);
                
            } catch (e){ console.error(e);}
        }
       return false;
    }
    
  setInfo( info : any) {
      this._info = info;
  }
    setPort( port : any) {
      this._port = port;
  }
 
   getSettings (){
       return this._settings;
   }
    async close(){
        await this._port.close();
    }
    clear (){
        
        this._port = null;
    }
    async connect(){
        if ("serial" in window.navigator) {
        
            const serial:any = window.navigator["serial"] ;
                 
            if(!this._port){
            
                if( this._settings.filter ){
                
                    this._port = await serial.requestPort({ filters: [this._settings.filter] });
                } else {
                    this._port = await serial.requestPort();
                }
            }
            // console.log(this._port);
            try{
          
                    await this._port.open( this._settings );
                    this._info = await this._port.getInfo();
            
                } catch ( e ){
            
                    await this._port.open( this._settings );
                    this._info = await this._port.getInfo();
                
                }
                
                if( this._settings.name ){       
                
                    localStorage.setItem(this._settings.name ,JSON.stringify(this._info) );
                }
                
          }
          //    getPorts()  return Serial.getPorts();   
    }
    
    async sendString( text:string ) {
           
        if(this._port&&this._port.writable){
            const encoder = new TextEncoder();
            const writer = this._port.writable.getWriter();
            await writer.write(encoder.encode(text));
            writer.releaseLock();
        }
    }
    getInfo(){
        return this._info;
    }
    async getInfoAsync(){
       if (this._port.getInfo) { return await this._port.getInfo();}
       return false;
    }
    getPort(){
        return this._port;
    }
    setReaderLock(){
       if(
        this._port.readable &&
        false === this._reader){
        this._reader = this._port.readable.getReader();
       }
    }
    async releaseReaderLock(){
       if(this._reader!==false){
        await this._reader.cancel(); 
        this._reader.releaseLock();
        this._reader=false;
       }
    }
    async readString (){
        if(this._reader==false) {return ""; }
        while (this._port.readable) {
                for (;;)  {
                    const { value, done } = await this._reader.read();
                    if (done) {
                               return done;
                     }
                    return new TextDecoder().decode(value);
                }
            } 
        return "";
    }  
 }
 
 
 export  class SerialUsbAutoConnectionDelegate{
     
     private  deviceConnectionList?:any = [];
     private  deviceConectingList?:any = [];
     
         async connectIfConnectionExists (){
        
          if ("serial" in window.navigator) {
        
            const serial:any = window.navigator["serial"] ;
                 
            const ports = await serial.getPorts();
            const temp:any[] = [];
               for( ; ports.length ; ){
                    const port =ports.pop();
                    if (port  ) {
                        
                        const connection:any = this.deviceConnectionList.shift();
                        if(!connection){ break; }
                        const connectedDeviceInfo  = connection.getInfoCacheIfExsist() ;
                        const info = await port.getInfo();
                         if( connectedDeviceInfo.usbProductId ===  info.usbProductId &&
                                connectedDeviceInfo.usbVendorId === info.usbVendorId ) {
                        const sleep1 =  ()=> {  return new Promise(resolve => setTimeout(resolve, 1000)) ; }
                        await sleep1();
                        try{ // 接続チェック 

                            await port.open( connection.getSettings() );
                            connection.setPort( port ) ;
                            connection.setInfo( info );        
                                      //for ( var i ; deviceConectingList ; 
                        } catch(e){ // 接続がないがデバイスがある場合
                           
                            console.error(e);  
                        }
                    }
                    temp.push(connection);
                     
                }
            }
           for( ;temp.length;){   if(!temp){break;} this.deviceConnectionList.push( temp.pop() ); }
        }
    }
     
    private _connect = async  (event:any) =>{
                     
                        const temp = [];
                        for( ;  this.deviceConnectionList.length ; ){  

                                const port = event.port || event.target || event ;
                                const connection:any = this.deviceConnectionList.pop();
                                const connectedDeviceInfo  = connection.getInfoCacheIfExsist() ;
                                const info = await port.getInfo();
                                temp.push(connection);
                                if(!connectedDeviceInfo){ continue; }
                            if( connectedDeviceInfo.usbProductId ===  info.usbProductId &&
                                connectedDeviceInfo.usbVendorId === info.usbVendorId ) {

                                  let isExsist = false;                    
                                   for(let k=0; k < this.deviceConectingList.length; k++){
                                       
                                      if ( connection === this.deviceConectingList[ k ] ){
                                          isExsist = true;
                                          break;
                                      }
                                   }
                             
                                  if(isExsist){  continue; }
                                 
                                    this.deviceConectingList.push(connection);
                                const sleep1 =  ()=> {  return new Promise(resolve => setTimeout(resolve, 2000)) ; }
                                await sleep1();
                                    try{ // 接続チェック 

                                      await port.open( connection.getSettings() );
                                      connection.setPort( port ) ;
                                      connection.setInfo( info );            
                                      //for ( var i ; deviceConectingList ; 
                                      this.deviceConectingList.filter(function(v:any){
                                                return ! [connection].includes(v);
                                        });
                                    } catch(e){ // 接続がないがデバイスがある場合
                                        console.error(e);  
                                    }
                              //  }, 1000 );
                               break;
                            }
                        }
                       
                     for( ;temp.length;){   this.deviceConnectionList.push( temp.pop() ); }
                    if(this.deviceConectingList){
                        for( ;this.deviceConectingList.length;){   this.deviceConectingList.pop() ; }
                     }
                     
                        // alert(this.deviceConnectionList);
    }
     
     enable(){
            if ("serial" in window.navigator) {
             const serial:any = window.navigator["serial"] ;
             
              serial.addEventListener("connect",this._connect );
            }
        }
     push ( connection: any ){        
               this.deviceConnectionList.push(connection);       
              
    }
}
 
 