
import { defineComponent } from "vue";
import { Serial } from "../modules/hardware/serial";

export default defineComponent({
  name: "SerialConnection" ,
    props:{ serial :Serial  },
  mounted :async function(){
  },methods:{

      async connect(){ 
        
         this.serial?.clear();
         await this.serial?.connect();
      }
  }
});


