export interface GeolocationCallback {
    geolocationReceived( pos :any ):void;
    geolocationFailed( error :any ):void;
}

export class Geolocation  {
    
    private callback: GeolocationCallback;
    private option?:any ;

    constructor(callback : GeolocationCallback  , option:any = {
		    "enableHighAccuracy": true,
		    "timeout": 10000,
		    "maximumAge": 0,
	    }) {
	    this.callback = callback;
        this.option = option;
    }
      async watchPosition(){
      // geolocation に対応しているか否かを確認
        if("geolocation" in navigator){
	 
	        navigator.geolocation.watchPosition(
	            this.callback.geolocationReceived,
	            this.callback.geolocationFailed ,
	            this.option
	        );
        }else{
	         throw("ブラウザが位置情報取得に対応していません");
        }
    }

    async getCurrentPosition(){
      // geolocation に対応しているか否かを確認
        if("geolocation" in navigator){
	    
	        navigator.geolocation.getCurrentPosition(
	                this.callback.geolocationReceived ,
	                this.callback.geolocationFailed ,
	                this.option
	            );
        }else{
	         throw("ブラウザが位置情報取得に対応していません");
        }
    }
      
 }