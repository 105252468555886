export class Media  {

    private _option:any ;
    stream ?: any ;
    
    constructor( option:any =  { video:true ,autdio: true } ) {
        
        this._option = option;
    }
    
   static async getConnectedDevices(type:string) {
       // device.kind =  "videoinput" or "audioinput" or "audiooutput
        const devices = await navigator.mediaDevices.enumerateDevices();
        return devices.filter(device => device.kind === type)
   }
   getCurrentCameraSize():any{
      if(this.stream.getTracks){
          const tracks =  this.stream.getTracks();
         // console.log(tracks)
            for( let i=0 ; i < tracks.length ; i++ ){
              if ( tracks[i].kind === "video" ){                    
                    const {width, height} = tracks[i].getSettings();
                    return { width : width , height: height };
              }
          }
      } return {width:0,height:0};
   }
    async open(){
        try{
            
            this.stream = await navigator.mediaDevices.getUserMedia(this._option);
        // console.log(1);
         //   console.log(this.stream);

            return true;

        } catch(e){
            
            if (this._option.secondary){
                try{
                
                  this.stream = await navigator.mediaDevices.getUserMedia(this._option.secondary);
                // console.log(e);
                //  console.log(2);
                //  console.log(this.stream);
       
                  return true;
        

                  }catch (e2){ 
                    console.error(e2);
                    return false;
                }
             console.error(e);
          return false;
             }
        }
    }     
 }