import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 col-10 offset-1 border-bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("strong", null, "接続中のデバイス: " + _toDisplayString(( _ctx.serial )? _ctx.serial.getInfo() : ""), 1),
    _createElementVNode("button", {
      class: "btn btn-dark",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.connect()))
    }, "接続")
  ]))
}